<template>
    <div>
        <vue-title title="Сменить пароль | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Сменить пароль</h1>
            </div>
            <div class="row">
                <div class="col-12 col-md-2 col-lg-3"></div>
                <div class="col-12 col-md-8 col-lg-6">
                    <div id="form-container">
                        <div v-if="okMessage" class="alert alert-success mb-3" role="alert">
                            {{ okMessage }}
                        </div>
                        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                            <strong>Внимание!</strong> {{ errorMessage }}
                        </div>
                        <form class="change-password-form" @submit.prevent="onSubmit">
                            <DxForm
                                    id="form"
                                    :col-count="1"
                                    :form-data="formData"
                                    :read-only="false"
                                    :show-colon-after-label="true"
                                    :show-validation-summary="true"
                                    :label-location="labelLocation"
                                    :disabled="isLoading"
                                    :ref="'changePasswordFormRef'"
                            >
                                <DxColCountByScreen :xs="1" :sm="1"/>
                                <DxSimpleItem
                                        data-field="Login"
                                        :editor-options="{disabled: true}"
                                >
                                    <DxLabel text="Логин"/>
                                    <DxRequiredRule message="Укажите ваш логин"/>
                                </DxSimpleItem>
                                <DxSimpleItem data-field="Password"
                                              :editor-options="passwordOptions"
                                >
                                    <DxLabel text="Текущий пароль"/>
                                    <DxRequiredRule message="Укажите ваш текущий пароль"/>
                                </DxSimpleItem>
                                <DxSimpleItem data-field="NewPassword"
                                              :editor-options="newPasswordOptions"
                                >
                                    <DxLabel text="Новый пароль"/>
                                    <DxRequiredRule message="Укажите ваш новый пароль"/>
                                </DxSimpleItem>
                                <DxSimpleItem data-field="NewPasswordConfirm"
                                              :editor-options="newPasswordConfirmOptions"
                                >
                                    <DxLabel text="Повторите новый пароль"/>
                                    <DxRequiredRule message="Повторите ваш новый пароль"/>
                                    <DxCustomRule message="Повторно указанный новый пароль не совпадает с Новым паролем" type="custom" :reevaluate="true" :validation-callback="validateNewPasswordConfirm" />
                                </DxSimpleItem>
                                <DxEmptyItem/>
                                <DxButtonItem
                                        :button-options="buttonOptions"
                                        cssClass="mt-2"
                                />
                            </DxForm>
                        </form>
                    </div>
                </div>
                <div class="col-12 col-md-2 col-lg-3"></div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import {httpClient} from '../../shared/services';
    import {Header, Footer} from '../../shared/components';
    import {
        DxForm,
        DxSimpleItem,
        DxLabel,
        DxButtonItem,
        DxRequiredRule,
        DxEmptyItem,
        DxColCountByScreen,
        DxCustomRule
    } from 'devextreme-vue/form';

    const changePasswordFormRef = "change-password-form-ref";

    export default {
        name: 'ProfileChangePassword',
        components: {
            Header,
            Footer,
            DxForm,
            DxSimpleItem,
            DxLabel,
            DxButtonItem,
            DxRequiredRule,
            DxEmptyItem,
            DxColCountByScreen,
            DxCustomRule
        },
        data() {
            return {
                headerRoutes,
                changePasswordFormRef,
                formData: {},
                labelLocation: 'top',
                okMessage: "",
                errorMessage: "",
                passwordOptions: {
                    mode: 'password'
                },
                newPasswordOptions: {
                    mode: 'password'
                },
                newPasswordConfirmOptions: {
                    mode: 'password'
                },
                buttonOptions: {
                    text: 'Сохранить',
                    type: 'default',
                    useSubmitBehavior: true
                },
            }
        },
        mounted() {
            this.formData = {
                Login: this.Agent?.login,
            };
        },
        methods: {
            onSubmit: async function () {
                this.okMessage = "";
                this.errorMessage = "";

                this.$store.dispatch('loading', true).then(() => {
                    const {
                        Login,
                        Password,
                        NewPassword,
                    } = this.formData;

                    let tokenId = this.Agent.tokenId;

                    httpClient.post(`v2/Agent/${tokenId}/ChangePassword/${NewPassword}`, {}, {
                            headers: {
                                'Login': Login,
                                'Password': Password
                            }
                        }).then((response) => {
                        response.data['login'] = Login;
                        this.$store.dispatch('logIn', response.data).then(() => {
                            this.$store.dispatch('loading', false);
                            this.okMessage = "Пароль успешно изменен.";

                            this.$refs['changePasswordFormRef'].instance.resetValues();

                            this.formData = {
                                Login: this.Agent?.login,
                            };
                        });
                    }).catch(this.handleError);
                });
            },
            handleError: function (error) {
                this.errorMessage = "Неверно указан Логин или Текущий пароль. Проверьте указанные вами данные.";
                this.$store.dispatch('loading', false);
                console.error(error);
            },
            validateNewPasswordConfirm(e) {
                if(this.formData.NewPassword != e.value) {
                    return false;
                }

                return true;
            },
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
